import axios from '@/common/axios'
import qs from 'qs'

export function addProductRecallApproval (data) {
  return axios({
    method: 'post',
    url: '/universal/approval/add',
    data: qs.stringify(data)
  })
}

export function deleteProductRecallApproval (id) {
  return axios({
    method: 'delete',
    url: '/universal/approval/delete/' + id
  })
}

export function updateProductRecallApproval (data) {
  return axios({
    method: 'put',
    url: '/universal/approval/update',
    data: qs.stringify(data)
  })
}

export function selectProductRecallApprovalInfo (id) {
  return axios({
    method: 'get',
    url: '/universal/approval/info/' + id
  })
}

export function selectProductRecallApprovalList (query) {
  return axios({
    method: 'get',
    url: '/universal/approval/list',
    params: query
  })
}
