<template>
  <div id="productRecallApproval">
    <el-dialog
      :title="productRecallApprovalFormTitle"
      width="1200px"
      :visible.sync="productRecallApprovalDialogVisible"
      :close-on-click-modal="false"
      @close="productRecallApprovalDialogClose"
    >
      <el-form
        ref="productRecallApprovalFormRef"
        :model="productRecallApprovalForm"
        :rules="productRecallApprovalFormRules"
        label-position="right"
        label-width="120px"
      >
        <el-row>
          <el-col :span="8">
            <el-form-item label="召回品名" prop="recallProductName">
              <el-input v-model="productRecallApprovalForm.recallProductName" placeholder="请输入召回品名" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="批号" prop="batchNo">
              <el-input v-model="productRecallApprovalForm.batchNo" placeholder="请输入批号" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="规格" prop="spec">
              <el-input v-model="productRecallApprovalForm.spec" placeholder="请输入规格" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="数量" prop="quantity">
              <el-input v-model="productRecallApprovalForm.quantity" placeholder="请输入数量" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="召回原因" prop="recallReason">
              <el-input v-model="productRecallApprovalForm.recallReason" placeholder="请输入召回原因" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="召回分级" prop="recallLevel">
              <el-input v-model="productRecallApprovalForm.recallLevel" placeholder="请输入召回分级" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="召回方式" prop="recallMethod">
              <el-input v-model="productRecallApprovalForm.recallMethod" placeholder="请输入召回方式" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="召回时限" prop="recallTimeLimit">
              <el-date-picker v-model="productRecallApprovalForm.recallTimeLimit" placeholder="请选择召回时限" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="产品最终使用地" prop="finalUsePlace">
              <el-input v-model="productRecallApprovalForm.finalUsePlace" placeholder="请输入产品最终使用地" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="召回要求" prop="recallRequirements">
              <el-input
                v-model="productRecallApprovalForm.recallRequirements"
                placeholder="请输入召回要求"
                type="textarea"
                :rows="3"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="建议补救或预防措施" prop="precaution">
              <el-input
                v-model="productRecallApprovalForm.precaution"
                placeholder="请输入建议补救或预防措施"
                type="textarea"
                :rows="3"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="召回医疗器械处置方式" prop="solvingMethod">
              <el-input
                v-model="productRecallApprovalForm.solvingMethod"
                placeholder="请输入召回医疗器械处置方式"
                type="textarea"
                :rows="3"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="召回申请人" prop="recallApplicant">
              <el-input
                v-model="productRecallApprovalForm.recallApplicant"
                placeholder="请输入召回申请人"
                type="textarea"
                :rows="3"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="签字" prop="applicantSignature">
              <el-input v-model="productRecallApprovalForm.applicantSignature" placeholder="请输入签字" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="日期" prop="applicantSignatureDate">
              <el-date-picker v-model="productRecallApprovalForm.applicantSignatureDate" placeholder="请选择日期" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="讨论意见" prop="discussOpinion">
              <el-input
                v-model="productRecallApprovalForm.discussOpinion"
                placeholder="请输入讨论意见"
                type="textarea"
                :rows="3"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="参会人员" prop="participants">
              <el-input v-model="productRecallApprovalForm.participants" placeholder="请输入参会人员" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="日期" prop="discussionDate">
              <el-date-picker v-model="productRecallApprovalForm.discussionDate" placeholder="请选择日期" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="管理者代表意见" prop="managerOpinion">
              <el-input
                v-model="productRecallApprovalForm.managerOpinion"
                placeholder="请输入管理者代表意见"
                type="textarea"
                :rows="3"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="签字" prop="managerSignature">
              <el-input v-model="productRecallApprovalForm.managerSignature" placeholder="请输入签字" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="日期" prop="managerSignatureDate">
              <el-date-picker v-model="productRecallApprovalForm.managerSignatureDate" placeholder="请选择日期" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="总经理意见" prop="generalManagerOpinion">
              <el-input
                v-model="productRecallApprovalForm.generalManagerOpinion"
                placeholder="请输入总经理意见"
                type="textarea"
                :rows="3"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="签字" prop="generalManagerSignature">
              <el-input v-model="productRecallApprovalForm.generalManagerSignature" placeholder="请输入签字" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="日期" prop="generalManagerSignatureDate">
              <el-date-picker v-model="productRecallApprovalForm.generalManagerSignatureDate" placeholder="请选择日期" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="备注" prop="remarks">
              <el-input
                v-model="productRecallApprovalForm.remarks"
                placeholder="请输入备注"
                type="textarea"
                :rows="3"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="productRecallApprovalDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="productRecallApprovalFormSubmit">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="召回品名">
        <el-input v-model="searchForm.recallProductName" placeholder="请输入召回品名" clearable />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button type="primary" icon="el-icon-plus" @click="handleAdd">
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="productRecallApprovalPage.list"
      row-key="id"
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :height="getTableHeight()"
    >
      <el-table-column prop="recallProductName" label="召回品名" />
      <el-table-column prop="batchNo" label="批号" />
      <el-table-column prop="spec" label="规格" />
      <el-table-column prop="quantity" label="数量" />
      <el-table-column prop="recallReason" label="召回原因" />
      <el-table-column prop="recallLevel" label="召回分级" />
      <el-table-column prop="recallMethod" label="召回方式" />
      <el-table-column label="召回时限">
        <template slot-scope="scope">
          <span v-if="scope.row.recallTimeLimit">{{ scope.row.recallTimeLimit.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="finalUsePlace" label="产品最终使用地" />
      <el-table-column prop="recallRequirements" label="召回要求" />
      <el-table-column prop="precaution" label="建议补救或预防措施" />
      <el-table-column prop="solvingMethod" label="召回医疗器械处置方式" />
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="productRecallApprovalPage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import { addProductRecallApproval, deleteProductRecallApproval, updateProductRecallApproval, selectProductRecallApprovalInfo, selectProductRecallApprovalList } from '@/api/universal/productRecallApproval'

export default {
  data () {
    return {
      productRecallApprovalDialogVisible: false,
      productRecallApprovalFormTitle: '',
      productRecallApprovalForm: {
        id: '',
        recallProductName: '',
        batchNo: '',
        spec: '',
        quantity: '',
        recallReason: '',
        recallLevel: '',
        recallMethod: '',
        recallTimeLimit: '',
        finalUsePlace: '',
        recallRequirements: '',
        precaution: '',
        solvingMethod: '',
        recallApplicant: '',
        applicantSignature: '',
        applicantSignatureDate: '',
        discussOpinion: '',
        participants: '',
        discussionDate: '',
        managerOpinion: '',
        managerSignature: '',
        managerSignatureDate: '',
        generalManagerOpinion: '',
        generalManagerSignature: '',
        generalManagerSignatureDate: '',
        remarks: ''
      },
      productRecallApprovalFormRules: {
        recallProductName: [{ required: true, message: '召回品名不能为空', trigger: ['blur', 'change']}]
      },
      productRecallApprovalPage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        recallProductName: ''
      }
    }
  },
  created () {
    selectProductRecallApprovalList(this.searchForm).then(res => {
      this.productRecallApprovalPage = res
    })
  },
  methods: {
    productRecallApprovalDialogClose () {
      this.$refs.productRecallApprovalFormRef.resetFields()
    },
    productRecallApprovalFormSubmit () {
      if (this.productRecallApprovalFormTitle === '产品召回审批单详情') {
        this.productRecallApprovalDialogVisible = false
        return
      }
      this.$refs.productRecallApprovalFormRef.validate(async valid => {
        if (valid) {
          if (this.productRecallApprovalFormTitle === '新增产品召回审批单') {
            await addProductRecallApproval(this.productRecallApprovalForm)
          } else if (this.productRecallApprovalFormTitle === '修改产品召回审批单') {
            await updateProductRecallApproval(this.productRecallApprovalForm)
          }
          this.productRecallApprovalPage = await selectProductRecallApprovalList(this.searchForm)
          this.productRecallApprovalDialogVisible = false
        }
      })
    },
    handleAdd () {
      this.productRecallApprovalFormTitle = '新增产品召回审批单'
      this.productRecallApprovalDialogVisible = true
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteProductRecallApproval(row.id)
        if (this.productRecallApprovalPage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.productRecallApprovalPage = await selectProductRecallApprovalList(this.searchForm)
      })
    },
    handleUpdate (index, row) {
      this.productRecallApprovalFormTitle = '修改产品召回审批单'
      this.productRecallApprovalDialogVisible = true
      this.selectProductRecallApprovalInfoById(row.id)
    },
    handleInfo (index, row) {
      this.productRecallApprovalFormTitle = '产品召回审批单详情'
      this.productRecallApprovalDialogVisible = true
      this.selectProductRecallApprovalInfoById(row.id)
    },
    selectProductRecallApprovalInfoById (id) {
      selectProductRecallApprovalInfo(id).then(res => {
        this.productRecallApprovalForm.id = res.id
        this.productRecallApprovalForm.recallProductName = res.recallProductName
        this.productRecallApprovalForm.batchNo = res.batchNo
        this.productRecallApprovalForm.spec = res.spec
        this.productRecallApprovalForm.quantity = res.quantity
        this.productRecallApprovalForm.recallReason = res.recallReason
        this.productRecallApprovalForm.recallLevel = res.recallLevel
        this.productRecallApprovalForm.recallMethod = res.recallMethod
        this.productRecallApprovalForm.recallTimeLimit = res.recallTimeLimit
        this.productRecallApprovalForm.finalUsePlace = res.finalUsePlace
        this.productRecallApprovalForm.recallRequirements = res.recallRequirements
        this.productRecallApprovalForm.precaution = res.precaution
        this.productRecallApprovalForm.solvingMethod = res.solvingMethod
        this.productRecallApprovalForm.recallApplicant = res.recallApplicant
        this.productRecallApprovalForm.applicantSignature = res.applicantSignature
        this.productRecallApprovalForm.applicantSignatureDate = res.applicantSignatureDate
        this.productRecallApprovalForm.discussOpinion = res.discussOpinion
        this.productRecallApprovalForm.participants = res.participants
        this.productRecallApprovalForm.discussionDate = res.discussionDate
        this.productRecallApprovalForm.managerOpinion = res.managerOpinion
        this.productRecallApprovalForm.managerSignature = res.managerSignature
        this.productRecallApprovalForm.managerSignatureDate = res.managerSignatureDate
        this.productRecallApprovalForm.generalManagerOpinion = res.generalManagerOpinion
        this.productRecallApprovalForm.generalManagerSignature = res.generalManagerSignature
        this.productRecallApprovalForm.generalManagerSignatureDate = res.generalManagerSignatureDate
        this.productRecallApprovalForm.remarks = res.remarks
      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectProductRecallApprovalList(this.searchForm).then(res => {
        this.productRecallApprovalPage = res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectProductRecallApprovalList(this.searchForm).then(res => {
        this.productRecallApprovalPage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectProductRecallApprovalList(this.searchForm).then(res => {
        this.productRecallApprovalPage = res
      })
    }
  }
}
</script>

<style>
</style>
